import React, { useState } from "react";

const SearchBar = ({ setSearchQuery }) => {
  const [inputValue, setInputValue] = useState("");
  
  return (
    <div className="flex items-center bg-white shadow-md rounded-lg p-2 my-4 mx-2">
      <input
        type="text"
        placeholder="검색어를 입력하세요..."
        className="flex-grow p-2 outline-none"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <button 
      className="bg-teal-500 text-white px-4 py-2 rounded-lg ml-2"
      onClick={() => setSearchQuery(inputValue)}
      >
        검색
      </button>
    </div>
  );
};

export default SearchBar;
