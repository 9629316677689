import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TagEditor from "../components/TagEditor"; // ✅ Adjust path as needed
import { uploadImages } from "../Utils/uploadImageUtil";

const AddRestaurantMenu = () => {
  const { rest_id } = useParams();
  const [menus, setMenus] = useState([]);
  const [menuForm, setMenuForm] = useState({
    rest_menu_id: "",
    sort: "",
    name: "",
    rec: "",
    price: "",
    vegetable: false,
    hajal: false,
    nuts: false,
    milk: false,
    description: "",
    image: null,
  });
  const [editingMenuId, setEditingMenuId] = useState(null);
  const [originalRestMenuId, setOriginalRestMenuId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(menus.length / itemsPerPage);
  const [menuTags, setMenuTags] = useState([]);
  const [ingredientTags, setIngredientTags] = useState([]);

  const paginatedMenus = menus.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    if (!rest_id) return;
    fetch(`/api/restaurants/${rest_id}`)
      .then((res) => res.json())
      .then((data) => {
        const sortedMenus = (data.menu || []).sort((a, b) => (a.rest_menu_id || 0) - (b.rest_menu_id || 0));
        setMenus(sortedMenus);
      })
      .catch((err) => console.error("❌ Failed to load menu data:", err));
  }, [rest_id]);

    const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setMenuForm({ ...menuForm, [name]: type === "checkbox" ? checked : value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setMenuForm({ ...menuForm, images: files });
  };

  const handleSaveMenu = async () => {
    let uploadedImagePaths = [];
  
    if (menuForm.images && menuForm.images.length > 0) {
      uploadedImagePaths = await uploadImages(menuForm.images, "restaurants_foods", rest_id, menuForm.rest_menu_id);
      console.log("✅ Uploaded Menu Images:", uploadedImagePaths);
    }
  
    const payload = {
      menu: {
        rest_menu_id: Number(menuForm.rest_menu_id),
        original_rest_menu_id: originalRestMenuId || null,
        menu_name: menuForm.name?.trim(),
        menu_price: menuForm.price?.trim() === "" ? null : menuForm.price,
        menu_sort: menuForm.sort,
        menu_rec: menuForm.rec,
        menu_des: menuForm.description,
        menu_veg: menuForm.vegetable ? 1 : 0,
        menu_haj: menuForm.hajal ? 1 : 0,
        menu_nuts: menuForm.nuts ? 1 : 0,
        menu_milk: menuForm.milk ? 1 : 0,
        tags: Array.isArray(menuTags) ? menuTags : [],
        ingredients: Array.isArray(ingredientTags) ? ingredientTags : [],
        image_paths: uploadedImagePaths || [], // ✅ multiple images!
      },
    };

    if (!payload.menu.menu_name) {
      alert("❗ Please, Input Menu's Title.");
      return;
    }

    fetch(`/api/restaurants/${rest_id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) throw new Error("Failed to save");
        return res.json();
      })
      .then((data) => {
        setEditingMenuId(null);
        setOriginalRestMenuId(null);
        setMenuForm({
          rest_menu_id: "",
          sort: "",
          name: "",
          rec: "",
          price: "",
          vegetable: false,
          hajal: false,
          nuts: false,
          milk: false,
          description: "",
          image: null,
        });
        setMenuTags([]);
        setIngredientTags([]);
        return fetch(`/api/restaurants/${rest_id}`)
          .then((res) => res.json())
          .then((data) => setMenus(data.menu || []));
      })
      .catch((err) => {
        console.error("❌ Save failed:", err);
      });
  };

  const handleEdit = async (menuId) => {
    const selectedMenu = menus.find((menu) => menu.rest_menu_id === menuId);
  
    if (selectedMenu) {
      setMenuForm({
        rest_menu_id: selectedMenu.rest_menu_id || "",
        sort: selectedMenu.menu_sort || "",
        name: selectedMenu.menu_name || "",
        rec: selectedMenu.menu_rec || "",
        price: selectedMenu.menu_price || "",
        vegetable: selectedMenu.menu_veg === 1,
        hajal: selectedMenu.menu_haj === 1,
        nuts: selectedMenu.menu_nuts === 1,
        milk: selectedMenu.menu_milk === 1,
        description: selectedMenu.menu_des || "",
        image: null,
      });
  
      try {
        const res = await fetch(`/api/tags/restaurants/${rest_id}/menu/${menuId}/tags`);
        if (!res.ok) throw new Error('Failed to fetch tags');
        
        const data = await res.json();
        console.log('Fetched tag data:', data); // Debug log
  
        // Handle both possible response formats
        let tags = [];
        let ingredients = [];
  
        if (Array.isArray(data)) {
          tags = data.filter(t => t.tag_type === 1).map(t => ({ tag: t.tag, tag_type: 1 }));
          ingredients = data.filter(t => t.tag_type === 2).map(t => ({ tag: t.tag, tag_type: 2 }));
        } else {
          tags = Array.isArray(data.tags)
            ? data.tags.map(t => ({ ...t, tag_type: 1 }))
            : [];
          ingredients = Array.isArray(data.ingredients)
            ? data.ingredients.map(i => ({ ...i, tag_type: 2 }))
            : [];
        }

        setMenuTags(tags);
        setIngredientTags(ingredients);
      } catch (err) {
        console.error("❌ Failed to fetch tags:", err);
        console.log('Menu tags to save:', menuTags);
        console.log('Ingredient tags to save:', ingredientTags);
        setMenuTags([]);
        setIngredientTags([]);
      }
  
      setEditingMenuId(menuId);
      setOriginalRestMenuId(menuId);
    };
  };

  const handleDelete = (menuId) => {
    if (!window.confirm("Are You Sure to Delete This Menu?")) return;

    fetch(`/api/restaurants/${rest_id}/menu/${menuId}`, {
      method: "DELETE",
    })
      .then((res) => {
        if (!res.ok) throw new Error("Delete Failed");
        return res.json();
      })
      .then(() => {
        return fetch(`/api/restaurants/${rest_id}`)
          .then((res) => res.json())
          .then((data) => setMenus(data.menu || []));
      })
      .catch((err) => {
        console.error("❌ Delete Failed:", err);
      });
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-md">
      <h2 className="text-lg font-bold">Add Restaurant's Menu Information</h2>
      <table className="w-full mt-4 text-sm">
        <thead>
          <tr className="bg-gray-100">
            <th>No</th>
            <th>Sort</th>
            <th>Name</th>
            <th>Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedMenus.map((menu, index) => (
            <tr key={menu.rest_menu_id || index} className="text-center">
              <td>{menu.rest_menu_id || "-"}</td>
              <td>{menu.menu_sort || "-"}</td>
              <td>{menu.menu_name || "-"}</td>
              <td>{menu.menu_price || "-"}</td>
              <td>
                <button onClick={() => handleEdit(menu.rest_menu_id)} className="mx-1">✏️</button>
                <button onClick={() => handleDelete(menu.rest_menu_id)} className="mx-1">🗑️</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-center items-center mt-4 space-x-2">
        <button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-gray-200 rounded disabled:opacity-50"
        >
          Prev
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-gray-200 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div>

      <button
        className="mt-2 bg-blue-500 text-white px-2 py-1 rounded"
        onClick={() => {
          const nextNo = menus.length > 0 ? Math.max(...menus.map(menu => menu.rest_menu_id || 0)) + 1 : 1;
          setMenuForm({
            rest_menu_id: nextNo,
            sort: "",
            name: "",
            rec: "",
            price: "",
            vegetable: false,
            hajal: false,
            nuts: false,
            milk: false,
            description: "",
            image: null,
          });
          setEditingMenuId(null);
          setOriginalRestMenuId(null);
          setMenuTags([]);
          setIngredientTags([]);
        }}
      >
        ➕ Add New Menu
      </button>

      <div className="mt-4 border p-4 rounded">
        <h2 className="text-md font-semibold mb-2">{editingMenuId ? `Edit Menu` : "Add New Menu"}</h2>

        <div className="grid grid-cols-1 gap-2">
          <label className="font-semibold">No</label>
          <input type="number" name="rest_menu_id" value={menuForm.rest_menu_id} onChange={handleChange} className="border p-1 rounded" />

          <label className="font-semibold">Sort</label>
          <select name="sort" value={menuForm.sort} onChange={handleChange} className="border p-1 rounded">
            <option value="">Select Sort</option>
            <option value="Appetizer">Appetizer</option>
            <option value="Salad">Salad</option>
            <option value="Main Dish">Main Dish</option>
            <option value="Dessert">Dessert</option>
            <option value="Drink">Drink</option>
            <option value="Combi">Combi</option>
            <option value="etc">etc</option>
          </select>

          <label className="font-semibold">Name</label>
          <input type="text" name="name" value={menuForm.name} onChange={handleChange} className="border p-1 rounded" />

          <label className="font-semibold">Ingredients</label>
          <TagEditor tags={ingredientTags || []} setTags={setIngredientTags} placeholder="Enter ingredients..." tag_type={2} />

          <label className="font-semibold">Price</label>
          <input type="text" name="price" value={menuForm.price} onChange={handleChange} className="border p-1 rounded" />

          <label className="font-semibold">Condition</label>
          <div className="grid grid-cols-2 gap-2">
            <label><input type="checkbox" name="vegetable" checked={menuForm.vegetable} onChange={handleChange} /> Vegetable</label>
            <label><input type="checkbox" name="hajal" checked={menuForm.hajal} onChange={handleChange} /> Halal</label>
            <label><input type="checkbox" name="nuts" checked={menuForm.nuts} onChange={handleChange} /> Includes Nuts</label>
            <label><input type="checkbox" name="milk" checked={menuForm.milk} onChange={handleChange} /> Includes Milk</label>
          </div>

          <label className="font-semibold">Description</label>
          <textarea name="description" value={menuForm.description} onChange={handleChange} className="border p-1 rounded h-24" />

          <TagEditor tags={menuTags || []} setTags={setMenuTags} placeholder="Add menu tags..." tag_type={1} />

          {/* Image Upload */}
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2">Upload Images (Max 10)</label>
            <div className="flex flex-wrap gap-2">
              {menuForm.images && menuForm.images.length > 0 ? (
                menuForm.images.map((file, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className="w-20 h-20 object-cover rounded"
                  />
                ))
              ) : (
                <label className="w-20 h-20 flex items-center justify-center border rounded cursor-pointer bg-gray-100">
                  +
                  <input 
                    type="file" 
                    accept="image/*" 
                    multiple 
                    onChange={handleImageUpload}
                    className="hidden" 
                  />
                </label>
              )}
            </div>
          </div>
          <button
            onClick={handleSaveMenu}
            className="mt-2 bg-green-500 text-white px-4 py-2 rounded"
          >
            {editingMenuId ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddRestaurantMenu;
