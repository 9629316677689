import React, { useState } from "react";

const TagEditor = ({ tags, setTags, tag_type = 1 }) => {
  const safeTags = Array.isArray(tags) ? tags : [];
  const [input, setInput] = useState("");

  const handleKeyDown = (e) => {
    if ((e.key === "Enter" || e.key === "," || e.key === " ") && input.trim()) {
      e.preventDefault();
      const trimmed = input.trim().replace(/,$/, "");

      // Prevent duplicate tags (compare by tag string only)
      if (trimmed && !safeTags.some(t => t.tag === trimmed)) {
        const newTag = { tag: trimmed, tag_type };
        setTags([...safeTags, newTag]);
        console.log("✅ Tag Added:", newTag);
      }

      setInput("");
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(safeTags.filter((tagObj) => tagObj.tag !== tagToRemove));
  };

  return (
    <div className="border p-2 rounded flex flex-wrap gap-1">
      {safeTags.map((tagObj, idx) => (
        <span
          key={`${tagObj.tag}-${idx}`}
          className={`${
            tagObj.tag_type === 2 || tagObj.tag_type === 5
            ? "bg-green-200 text-green-800" // 2. Ingredient
            : "bg-blue-200 text-blue-800" // 1. General Tag
          } px-2 py-1 rounded text-sm flex items-center`}
        >
          #{tagObj.tag}
          <button
            type="button"
            onClick={() => removeTag(tagObj.tag)}
            className="ml-1 text-red-500"
          >
            ✕
          </button>
        </span>
      ))}
      <input
        type="text"
        className="flex-1 p-1"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Press Enter to add a tag"
      />
    </div>
  );
};

export default TagEditor;