import React, { useState } from "react";
import TagEditor from "../components/TagEditor";

const AddFood = () => {
  const [foodForm, setFoodForm] = useState({
    name: "",
    description: "",
    veg: false,
    hajal: false,
    nuts: false,
    milk: false,
    price: "",
    image: null,
  });

  const [foodTags, setFoodTags] = useState([]); // tag_type = 4
  const [ingredientTags, setIngredientTags] = useState([]); // tag_type = 5

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFoodForm({ ...foodForm, [name]: type === "checkbox" ? checked : value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFoodForm({ ...foodForm, image: file });
  };

  const handleSave = async () => {
    if (!foodForm.name?.trim()) {
      alert("❗ Please enter the food name.");
      return;
    }

    const payload = {
      fd_name: foodForm.name.trim(),
      fd_des: foodForm.description || null,
      fd_avprice: foodForm.price || null,
      fd_veg: foodForm.veg ? 1 : 0,
      fd_haj: foodForm.hajal ? 1 : 0,
      fd_nuts: foodForm.nuts ? 1 : 0,
      fd_milk: foodForm.milk ? 1 : 0,
      tags: foodTags,
      ingredients: ingredientTags,
    };

    try {
      const res = await fetch("/api/foods/add", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!res.ok) throw new Error("Failed to add food");
      const result = await res.json();
      console.log("✅ Food added:", result);

      // Reset form
      setFoodForm({
        name: "",
        description: "",
        veg: false,
        hajal: false,
        nuts: false,
        milk: false,
        price: "",
        image: null,
      });
      setFoodTags([]);
      setIngredientTags([]);
    } catch (error) {
      console.error("❌ Failed to save food:", error);
    }
  };

  return (
    <div className="p-4 bg-white shadow rounded">
      <h2 className="text-lg font-bold mb-2">Add Food Information</h2>

      <label className="font-semibold">Name</label>
      <input type="text" name="name" value={foodForm.name} onChange={handleChange} className="border p-1 w-full rounded mb-2" />

      <label className="font-semibold">Description</label>
      <textarea name="description" value={foodForm.description} onChange={handleChange} className="border p-1 w-full rounded mb-2 h-20" />

      <label className="font-semibold block mb-1">Conditions</label>
      <div className="flex flex-wrap gap-4 mb-2">
        <label><input type="checkbox" name="veg" checked={foodForm.veg} onChange={handleChange} /> Vegetable</label>
        <label><input type="checkbox" name="hajal" checked={foodForm.hajal} onChange={handleChange} /> Halal</label>
        <label><input type="checkbox" name="nuts" checked={foodForm.nuts} onChange={handleChange} /> Nuts</label>
        <label><input type="checkbox" name="milk" checked={foodForm.milk} onChange={handleChange} /> Milk</label>
      </div>

      <label className="font-semibold">Price</label>
      <input type="text" name="price" value={foodForm.price} onChange={handleChange} className="border p-1 w-full rounded mb-2" />

      <label className="font-semibold">Food Tags</label>
      <TagEditor tags={foodTags} setTags={setFoodTags} tag_type={4} placeholder="e.g. spicy, sweet, meat" />

      <label className="font-semibold mt-2">Ingredients</label>
      <TagEditor tags={ingredientTags} setTags={setIngredientTags} tag_type={5} placeholder="e.g. cabbage, garlic, tofu" />

      <div className="mt-4">
        <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded">Save Food</button>
      </div>
    </div>
  );
};

export default AddFood;
