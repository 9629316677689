import React, { useState } from "react";
import axios from "axios";
import { countries } from "countries-list";
import TagEditor from "../components/TagEditor"; // ✅ Adjust path as needed

// Convert country list to array
const countryList = Object.entries(countries).map(([code, details]) => ({
  name: details.name,
  code: details.phone[0] ? `+${details.phone[0]}` : "+1",
  isoCode: code,
}));

const AddRestaurant = () => {
  const initialFormState = {
    rest_id: "",
    rest_name: "",
    rest_address1: "",
    rest_address2: "",
    rest_city: "",
    rest_state: "",
    rest_zipcode: "",
    rest_country: "Korea",
    rest_ph_number: "",
    rest_url: "",
    rest_break_start: "",
    rest_break_end: "",
    rest_menu_vege: false,
    rest_menu_haj: false,
    rest_con_outtable: false,
    rest_con_ac: false,
    rest_con_sitfloor: false,
    rest_con_kidzone: false,
    rest_des: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [images, setImages] = useState([]); // Image previews
  const [imageFiles, setImageFiles] = useState([]); // File objects for upload
  const [restaurantTags, setRestaurantTags] = useState([]); // Tags for restaurant

  // ✅ Handle form input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // ✅ Handle country selection
  const handleCountryChange = (e) => {
    setFormData({ ...formData, rest_country: e.target.value });
  };

  // ✅ Handle multiple image uploads (limit: 10)
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Check if images exceed the limit of 10
    if (images.length + files.length > 10) {
      alert("You can upload up to 10 images.");
      return;
    }

    const newImageURLs = files.map((file) => URL.createObjectURL(file));
    setImages([...images, ...newImageURLs]);
    setImageFiles([...imageFiles, ...files]);
  };

    // ✅ Handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
    
      try {
        const restaurantResponse = await axios.post(
          "/api/restaurants/add",
          formData
        );
    
        if (restaurantResponse.data.success) {
          const rest_id = restaurantResponse.data.rest_id;
    
          // 🔽 Upload tags to rest_tag_db
          if (restaurantTags.length > 0) {
            await axios.post("/api/restaurant-tags", {
              rest_id,
              tags: restaurantTags,
            });
          }
    
          // ✅ Upload images if available
          if (imageFiles.length > 0) {
            const imageData = new FormData();
            // Add the `id` to FormData
            imageData.append("id", rest_id);  // Ensure `id` is added here
            imageData.append("category", "restaurants");
            imageFiles.forEach((file) => imageData.append("images", file));
    
            await axios.post("/api/upload", imageData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          }

          alert("✅ Restaurant added successfully!");
          setFormData(initialFormState); // Reset form
          setImages([]); // Reset images
          setImageFiles([]); // Reset image files
        }

      } catch (error) {
        console.error("❌ Error adding restaurant:", error);
        alert("Failed to add restaurant. Please check the console for details.");
      }
    };
  
  return (
    <div className="mx-auto p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-lg font-bold mb-4">Add Restaurant Information</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="rest_name" placeholder="Restaurant Name" className="w-full border p-2 mb-2" value={formData.rest_name} onChange={handleChange} required />
        <input type="text" name="rest_address2" placeholder="Address2" className="w-full border p-2 mb-2" value={formData.rest_address2} onChange={handleChange} />
        <input type="text" name="rest_address1" placeholder="Address" className="w-full border p-2 mb-2" value={formData.rest_address1} onChange={handleChange} />
        <input type="text" name="rest_city" placeholder="City" className="w-full border p-2 mb-2" value={formData.rest_city} onChange={handleChange} />
        <input type="text" name="rest_state" placeholder="State" className="w-full border p-2 mb-2" value={formData.rest_state} onChange={handleChange} />
        <input type="text" name="rest_zipcode" placeholder="Zip Code" className="w-full border p-2 mb-2" value={formData.rest_zipcode} onChange={handleChange} />

        <select name="rest_country" value={formData.rest_country} onChange={handleCountryChange} className="w-full border p-2 mb-2">
          {countryList.map((country) => (
            <option key={country.isoCode} value={country.name}>{country.name}</option>
          ))}
        </select>

        <input type="text" name="rest_ph_number" placeholder="Phone Number" className="w-full border p-2 mb-2" value={formData.rest_ph_number} onChange={handleChange} />
        <input type="text" name="rest_url" placeholder="Homepage URL" className="w-full border p-2 mb-2" value={formData.rest_url} onChange={handleChange} />
        
        {/* ✅ Break Time */}
        <div className="flex space-x-2 mb-2">
          Start <input type="time" name="rest_break_start" className="border p-2 w-1/2" value={formData.rest_break_start} onChange={handleChange} />
          End <input type="time" name="rest_break_end" className="border p-2 w-1/2" value={formData.rest_break_end} onChange={handleChange} />
        </div>

        {/* ✅ Conditions */}
        <div className="grid grid-cols-2 gap-2 mb-2">
          {["rest_menu_vege", "rest_menu_haj", "rest_con_outtable", "rest_con_ac", "rest_con_sitfloor", "rest_con_kidzone"].map((cond) => (
            <label key={cond} className="flex items-center space-x-2">
              <input type="checkbox" name={cond} checked={formData[cond]} onChange={handleChange} />
              <span>{cond.replace(/rest_/, "").replace(/_/g, " ")}</span>
            </label>
          ))}
        </div>

        {/* ✅ Description */}
        <textarea name="rest_des" placeholder="Description" className="w-full border p-2 mb-2" value={formData.rest_des} onChange={handleChange}></textarea>

        <TagEditor tags={restaurantTags} setTags={setRestaurantTags} placeholder="Add restaurant tags..." />

        {/* ✅ Image Upload */}
        <div className="mb-4">
          <label className="block mb-2">Restaurant Images</label>
          <div className="flex flex-wrap gap-2">
            {images.map((img, index) => (
              <img key={index} src={img} alt="Preview" className="w-20 h-20 object-cover rounded" />
            ))}
            {images.length < 10 && (
              <label className="w-20 h-20 border flex items-center justify-center rounded cursor-pointer bg-gray-100">
                +
                <input type="file" accept="image/*" multiple onChange={handleImageChange} className="hidden" />
              </label>
            )}
          </div>
        </div>

        <button type="submit" className="w-full bg-teal-500 text-white py-2 rounded-lg">Add / Correct</button>
      </form>
    </div>
  );
};

export default AddRestaurant;
