import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import SignUp from "./SignUp";
import { useNavigate } from "react-router-dom";

const Header = ({ 
  handleHomeClick, 
  setShowSearchResults, 
  setShowDetails, 
  setSelectedRestaurant,
  setRestaurantDetails, 
  setMenuItems,
  setShowReview  // Added this prop to match the "Hide Details" functionality
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const navigate = useNavigate();

  // Enhanced handleMenupicClick to ensure proper execution and match "Hide Details" functionality
  const handleMenupicClick = (e) => {
    // Prevent default to avoid any unexpected behavior
    e.preventDefault();
    console.log("Menupic title clicked in Header.js");
    
    // Close any open menus if they exist
    if (menuOpen) setMenuOpen(false);
    if (showSignUp) setShowSignUp(false);
    
    // Replicate the "Hide Details" functionality directly here
    setSelectedRestaurant(null);
    setRestaurantDetails(null);
    setMenuItems([]);
    setShowDetails(false);
    setShowReview(false);  // Added this to match "Hide Details"
    
    // Then call the original handleHomeClick for any additional functionality
    handleHomeClick();
  };

  return (
    <>
      <header className="w-full h-[60px] bg-[#00CA7E] text-white flex items-center justify-center px-4 shadow-md relative">
        <button 
          className="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 bg-transparent border-none outline-none"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <FiMenu size={24} className="text-white" />
        </button>

        {/* Use the enhanced handler for Menupic click */}
        <h1 
          className="text-[30pt] font-bold text-white text-center cursor-pointer"
          onClick={handleMenupicClick}  // Use enhanced handler
        >
          Menupic
        </h1>
      </header>

      {/* Sidebar Menu */}
      {menuOpen && (
        <div className="absolute top-0 left-0 w-[250px] h-full bg-white shadow-lg p-4 z-50">
          <button
            className="absolute top-4 right-4 text-gray-600"
            onClick={() => setMenuOpen(false)}
          >
            ✕
          </button>          
          <h2 className="text-lg font-bold mb-4">Sign in Menu</h2>
          <div className="mb-4">
            <label className="block text-sm">ID</label>
            <input type="text" className="w-full border rounded p-1" />
          </div>
          <div className="mb-4">
            <label className="block text-sm">PW</label>
            <input type="password" className="w-full border rounded p-1" />
          </div>
          <button className="w-full bg-gray-300 text-black py-1 rounded">Sign in</button>
          <div className="flex justify-between text-sm mt-2">
            <a href="#" className="text-blue-500">Forget Password</a>
            <button 
              className="text-blue-500"
              onClick={() => setShowSignUp(true)}
            >Sign up
            </button>
          </div>
          <div className="mt-4">
            <button className="w-full border rounded p-2 mb-2">My Restaurant</button>
            <button className="w-full border rounded p-2 mb-2">My Menu</button>
            <button className="w-full border rounded p-2">My Review</button>
          </div>
        </div>
      )}

      {/* Sign-Up Form (Replaces Menu) */}
      {showSignUp && (
        <div className="absolute top-0 left-0 w-[250px] h-full bg-white shadow-lg p-4 z-50">
          {/* Back Button */}
          <button
            className="absolute top-4 right-4 text-gray-600"
            onClick={() => setShowSignUp(false)}
          >
            ✕
          </button>

          <h2 className="text-lg font-bold mb-4">Sign Up</h2>
          <SignUp /> {/* Displays SignUp Component */}
        </div>
      )}
    </>
  );
};

export default Header;