import React from "react";

const MapSection = () => {
  return (
    <div className="flex items-center h-60 p-2 mx-2 bg-gray-300 rounded-lg overflow-hidden">
      <iframe
        title="Manupic Location Map"  // ✅ title 속성 추가
        className="w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!..."
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default MapSection;
