import React, { useState } from "react";
import axios from "axios";
import { countries } from "countries-list"; // Import country list

// Convert countries object into an array
const countryList = Object.entries(countries).map(([code, details]) => ({
  name: details.name.toLowerCase(), // Convert to lowercase for better matching
  code: details.phone[0] ? `+${details.phone[0]}` : "+1", // Default to +1 if no phone code
  isoCode: code,
}));

const SignUp = () => {
  const [formData, setFormData] = useState({
    user_id: "",
    user_pw: "",
    user_name_first: "",
    user_name_last: "",
    user_name_mid: "",
    user_email: "",
    user_hp: "",
    user_country: countryList[0].name, // Default to first country in the list
    country_code: countryList[0].code, // Default to first country's phone code
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "user_country") {
      // Convert input to lowercase for better matching
      const countryInput = value.trim().toLowerCase();

      // Find country code based on user input
      const selectedCountry = countryList.find((country) => country.name === countryInput);

      setFormData({
        ...formData,
        user_country: value, // Keep the original user input
        country_code: selectedCountry ? selectedCountry.code : "+1", // Default to +1 if not found
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle phone number input
  const handlePhoneChange = (e) => {
    let value = e.target.value;

    // Remove hyphens ("-") from phone number
    value = value.replace(/-/g, "");

    // Remove leading zero after country code
    if (value.startsWith("0")) {
      value = value.substring(1);
    }

    setFormData({
      ...formData,
      user_hp: value,
    });
  };

  // Submit Form
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Combine country code with phone number
    const fullPhoneNumber = formData.country_code + formData.user_hp;

    try {
      const response = await axios.post("/api/signup", {
        ...formData,
        user_hp: fullPhoneNumber, // Send formatted phone number
      });

      if (response.data && response.data.message) {
        alert(response.data.message);
        window.location.href = "/"; // ✅ Redirect to main menu
      } else {
        alert("Signup successful, but no response message received.");
      }
    } catch (error) {
      console.error("❌ Signup API Error:", error);
      alert(
        "Error during signup: " +
          (error.response?.data?.error || "Check console for details.")
      );
    }
  };

  return (
    <div className="w-full max-w-xs mx-auto p-4 bg-white shadow-lg rounded">
      <h2 className="text-xl font-semibold text-center mb-4">Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-2">ID*</label>
          <input
            type="text"
            name="user_id"
            placeholder="User ID"
            value={formData.user_id}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">PW*</label>
          <input
            type="password"
            name="user_pw"
            placeholder="Password"
            value={formData.user_pw}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">First name*</label>
          <input
            type="text"
            name="user_name_first"
            placeholder="First Name"
            value={formData.user_name_first}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Mid name</label>
          <input
            type="text"
            name="user_name_mid"
            placeholder="Mid Name (Optional)"
            value={formData.user_name_mid}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Last name*</label>
          <input
            type="text"
            name="user_name_last"
            placeholder="Last Name"
            value={formData.user_name_last}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">E-mail*</label>
          <input
            type="email"
            name="user_email"
            placeholder="E-mail"
            value={formData.user_email}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2">Country*</label>
          <select
            name="user_country"
            value={formData.user_country}
            onChange={handleChange}
            required
            className="w-full px-3 py-2 border rounded-md"
          >
            {countryList.map((country) => (
              <option key={country.isoCode} value={country.name}>
                {country.name} ({country.code})
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2">Phone Number*</label>
          <div className="flex">
            <span className="px-3 py-2 border rounded-l-md bg-gray-200">
              {formData.country_code}
            </span>
            <input
              type="text"
              name="user_hp"
              placeholder="Phone Number"
              value={formData.user_hp}
              onChange={handlePhoneChange}
              required
              className="w-full px-3 py-2 border rounded-r-md"
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-teal-500 text-white py-2 rounded-lg mt-4"
        >
          Apply
        </button>
        <p className="mt-2 text-gray-600 text-center text-xs">* is required</p>
      </form>
    </div>
  );
};

export default SignUp;
