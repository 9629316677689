import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { uploadImages, submitFoodReview } from "../Utils/writeReviewUtil";
import TagEditor from "./TagEditor";

const WriteFoodReview = () => {
  const { fd_id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [score, setScore] = useState(5.0);
  const [tags, setTags] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
    const previewUrls = files.map((file) => URL.createObjectURL(file));
    setPreviews(previewUrls);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await submitFoodReview({
      fd_id,
      title,
      content,
      score,
      tags,
      imageFiles,
    });

    // Reset form
    setTitle("");
    setContent("");
    setScore(5.0);
    setTags([]);
    setImageFiles([]);
    setPreviews([]);
  };

  return (
    <div className="mx-auto p-4 bg-white shadow-md rounded-lg max-w-2xl">
      <h2 className="text-2xl font-bold mb-4">✍️ Write Food Review</h2>

      <form onSubmit={handleSubmit}>
        {/* Title */}
        <input
          type="text"
          placeholder="Review Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full border p-2 mb-4"
          required
        />

        {/* Content */}
        <textarea
          placeholder="Write your review here..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="w-full border p-2 mb-4"
          rows={6}
          required
        />

        {/* Score */}
        <div className="flex items-center space-x-2 mb-4">
          <label className="font-semibold">Taste Score:</label>
          <input
            type="number"
            min="0"
            max="10"
            step="0.1"
            value={score}
            onChange={(e) => setScore(parseFloat(e.target.value))}
            className="border p-2 w-24"
            required
          />
          <span>/ 10.0</span>
        </div>

        {/* Tags */}
        <TagEditor tags={tags} setTags={setTags} />

        {/* Image Upload */}
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-2">Upload Images (Max 10)</label>
          <div className="flex flex-wrap gap-2">
            {previews.length > 0 ? (
              previews.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Preview ${index}`}
                  className="w-32 h-32 object-cover rounded"
                />
              ))
            ) : (
              <label className="w-20 h-20 flex items-center justify-center border rounded cursor-pointer bg-gray-100">
                +
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                  className="hidden"
                />
              </label>
            )}
          </div>
        </div>

        {/* Submit */}
        <button
          type="submit"
          className="w-full bg-teal-500 text-white py-2 rounded-lg hover:bg-teal-600"
        >
          Submit Review
        </button>
      </form>
    </div>
  );
};

export default WriteFoodReview;
