import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AddRestaurantMenu from "./AddRestaurantMenu";
import WriteReview from "./WriteReview";

const RestaurantDetails = ({ setShowAddMenu, setSelectedRestaurant }) => {
  const { rest_id } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [showMenuEditor, setShowMenuEditor] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [selectedMenuId, setSelectedMenuId] = useState(null);

  useEffect(() => {
    if (!rest_id) return;

    console.log("🔍 Fetching restaurant with ID:", rest_id);

    fetch(`/api/restaurants/${rest_id}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }
        setRestaurant(data);
      })
      .catch((err) => {
        console.error("❌ Fetch error:", err.message);
        setError(err.message);
      });
  }, [rest_id]);

  const handleAddMenu = () => {
    setSelectedRestaurant(rest_id);
    setShowMenuEditor(true);
  };

  if (error) {
    return (
      <div className="p-4 text-center text-red-500">
        <p>🚫 Failed to load restaurant: {error}</p>
        <button onClick={() => navigate("/")} className="mt-2 underline text-blue-600">
          Go back
        </button>
      </div>
    );
  }

  if (showMenuEditor) {
    return (
      <AddRestaurantMenu
        rest_id={rest_id}
        initialMenus={restaurant?.menu || []}
      />
    );
  }

  if (!restaurant) return <p className="text-gray-500">Loading restaurant info...</p>;

  return (
    <div className="w-full bg-white shadow-md rounded-md p-4 mt-4">
      <div className="flex flex-col items-center">
        
        {/* Restaurant Info */}
        <img
          src={restaurant.image || "/default-image.png"}
          alt={restaurant.rest_name}
          className="w-full max-w-lg object-cover rounded-md"
        />
        <h2 className="text-xl font-bold mt-2">{restaurant.rest_name}</h2>
        <p className="text-gray-600">{restaurant.rest_address1}</p>
        <p className="text-gray-600">{restaurant.rest_address2}</p>
        <p className="text-gray-600">{restaurant.rest_ph_number}</p>

        {restaurant.rest_url && (
          <a
            href={restaurant.rest_url}
            className="text-blue-500 mt-2"
            target="_blank"
            rel="noreferrer"
          >
            Visit Website
          </a>
        )}

        {/* ✅ Write Restaurant Review Button */}
        <button
          className="mt-4 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
          onClick={() => navigate(`/restaurants/${restaurant.rest_id}/review`)}  // <-- This is perfect
        >
          📄 Write Restaurant Review
        </button>

        {/* Back Button */}
        <button
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
          onClick={() => navigate("/")}
        >
          Back to List
        </button>
      </div>

      {/* Menu Section */}
      <div className="mt-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold">Menu</h3>
          <button
            onClick={handleAddMenu}
            className="bg-yellow-400 text-black font-bold border-2 border-red-500 p-2 rounded"
          >
            ➕ Add/Edit Menu
          </button>
        </div>

        <div className="mt-2">
          {!restaurant.menu || restaurant.menu.length === 0 ? (
            <p className="text-gray-500">No menu items available.</p>
          ) : (
            restaurant.menu.map((menu) => (
              <div
                key={menu.rest_menu_id}
                className="bg-white shadow-md rounded-lg p-4 mt-2 flex flex-col sm:flex-row items-center"
              >
                {/* Thumbnail */}
                <div className="flex-shrink-0">
                  <img
                    src={menu.menu_image || "/default-menu.png"}
                    alt={menu.menu_name}
                    className="w-20 h-20 object-cover rounded-md"
                  />
                </div>

                {/* Menu Text */}
                <div className="flex flex-col flex-grow ml-4 overflow-hidden">
                  <h2 className="text-lg font-semibold truncate">{menu.menu_name}</h2>
                  <p className="text-sm text-gray-500 break-words">{menu.menu_des}</p>
                  <p className="text-sm text-gray-500">{menu.menu_price}원</p>
                </div>

                {/* Buttons */}
                <div className="flex flex-col space-y-2 mt-2 sm:mt-0 sm:ml-4">
                {/* ✅ Write Menu Review Button */}
                <button
                  className="w-24 h-10 bg-green-500 hover:bg-green-600 text-white font-semibold rounded text-xs flex items-center justify-center"
                  onClick={() => navigate(`/restaurants/${restaurant.rest_id}/menus/${menu.rest_menu_id}/review`)} // <-- Also perfect
                >
                  ✍️ Write Review
                </button>

                {/* ✅ Read Menu Reviews Button */}
                <button
                  className="w-24 h-10 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded text-xs flex items-center justify-center"
                  onClick={() => navigate(`/restaurants/${restaurant.rest_id}/menu/${menu.rest_menu_id}/reviews`)}
                >
                  📖 Read Reviews
                </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default RestaurantDetails;
