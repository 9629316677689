import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import SearchBar from "./components/SearchBar";
import Header from "./components/Header";
import MapSection from "./components/MapSection";
import SearchResults from "./components/SearchResults";
import RestaurantDetails from "./components/RestaurantDetails";
import AddRestaurant from "./components/AddRestaurant";
import AddFood from "./components/AddFood";
import AddRestaurantMenu from "./components/AddRestaurantMenu";
import FloatingButton from "./components/FloatingButton";
import WriteRestReview from "./components/WriteRestReview";  // ✅ Correct import
import WriteMenuReview from "./components/WriteMenuReview";  // ✅ Correct import
import WriteFoodReview from "./components/WriteFoodReview"; // ← make sure you import it

function App() {
  const [searchQuery, setSearchQuery] = useState("");
  const [restaurants, setRestaurants] = useState([]);
  const [foods, setFoods] = useState([]);
  const [showAddRestaurant, setShowAddRestaurant] = useState(false);
  const [showAddFood, setShowAddFood] = useState(false);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
  const [showSearchResults, setShowSearchResults] = useState(true);
  const [showOnlyRestaurants, setShowOnlyRestaurants] = useState(true);
  const [showOnlyFoods, setShowOnlyFoods] = useState(true);

  const [selectedRestaurant, setSelectedRestaurant] = useState(null);
  const [restaurantDetails, setRestaurantDetails] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const isAddRestaurantPage = location.pathname === "/add-restaurant";
  const isAddFoodPage = location.pathname === "/add-food";
  const isRestaurantDetailPage = /^\/restaurants\/[^/]+$/.test(location.pathname);

  const [shouldHideSearchAndMap, setShouldHideSearchAndMap] = useState(false);

  useEffect(() => {
    const hideSearchMap =
      isAddRestaurantPage ||
      isAddFoodPage ||
      isRestaurantDetailPage ||
      showDetails ||
      showAddRestaurant ||
      showAddFood ||
      showReview;
  
    setShouldHideSearchAndMap(hideSearchMap);
  }, [
    isAddRestaurantPage,
    isAddFoodPage,
    isRestaurantDetailPage,
    showDetails,
    showAddRestaurant,
    showAddFood,
    showReview,
  ]);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const res = await fetch("/api/display/restaurants");
        const data = await res.json();
        setRestaurants(data);
      } catch (error) {
        console.error("❌ Error fetching restaurants:", error);
      }
    };
  
    const fetchFoods = async () => {
      try {
        const res = await fetch("/api/display/foods");
        const data = await res.json();
        setFoods(data);
      } catch (error) {
        console.error("❌ Error fetching foods:", error);
      }
    };
  
    fetchRestaurants();
    fetchFoods();
  }, []);

  const handleHomeClick = () => {
    navigate("/");
    setTimeout(() => {
      setSelectedRestaurant(null);
      setRestaurantDetails(null);
      setMenuItems([]);
      setShowDetails(false);
      setShowReview(false);
      setShowSearchResults(true);
      setSearchQuery("");
      setShowAddRestaurant(false);
      setShowAddFood(false);
      setShowAddMenu(false);
      setShowOnlyRestaurants(true);
      setShowOnlyFoods(true);
    }, 0);
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <Header 
        setSearchQuery={setSearchQuery}
        setShowDetails={setShowDetails}
        setShowReview={setShowReview}
        setShowAddRestaurant={setShowAddRestaurant}
        setShowAddFood={setShowAddFood}
        handleHomeClick={handleHomeClick}
        setSelectedRestaurant={setSelectedRestaurant}
        setRestaurantDetails={setRestaurantDetails}
        setMenuItems={setMenuItems}
        setShowSearchResults={setShowSearchResults}
        setShowOnlyRestaurants={setShowOnlyRestaurants}
        setShowOnlyFoods={setShowOnlyFoods}
      />

      {!shouldHideSearchAndMap && (
        <>
          <SearchBar setSearchQuery={setSearchQuery} />
          <MapSection />
        </>
      )}

      <div className="my-2 p-2 w-full">
        <Routes>
          <Route
            path="/"
            element={
              <SearchResults
                restaurants={restaurants}
                foods={foods}
                searchQuery={searchQuery}
                setShowDetails={setShowDetails}
                setShowReview={setShowReview}
                selectedRestaurant={selectedRestaurant}
                setSelectedRestaurant={setSelectedRestaurant}
                showOnlyRestaurants={showOnlyRestaurants}
                setShowOnlyRestaurants={setShowOnlyRestaurants}
                showOnlyFoods={showOnlyFoods}
                setShowOnlyFoods={setShowOnlyFoods}
                setSelectedRestaurantId={setSelectedRestaurantId}
              />
            }
          />
          <Route path="/add-restaurant" element={<AddRestaurant />} />
          <Route path="/add-food" element={<AddFood />} />

          {/* ✅ New separated routes for reviews */}
          <Route path="/restaurants/:rest_id/review" element={<WriteRestReview />} />
          <Route path="/restaurants/:rest_id/menus/:menu_id/review" element={<WriteMenuReview />} />
          <Route path="/foods/:fd_id/review" element={<WriteFoodReview />} />

          <Route 
            path="/restaurants/:rest_id" 
            element={
              <RestaurantDetails 
                setShowDetails={setShowDetails}
                setSelectedRestaurant={setSelectedRestaurant}
                setRestaurantDetails={setRestaurantDetails}
                setMenuItems={setMenuItems}
                setShowAddMenu={setShowAddMenu}
              />
            } 
          />
        </Routes>
      </div>

      {/* Floating Button */}
      <FloatingButton onOpenAddRestaurant={() => setShowAddRestaurant(true)} />

      {/* Popup for Add Restaurant */}
      {showAddRestaurant && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-md relative">
            <button 
              className="absolute top-2 right-4 text-gray-600"
              onClick={() => setShowAddRestaurant(false)}
            >
              ✕
            </button>
            <AddRestaurant />
          </div>
        </div>
      )}

      {/* Popup for Add Food */}
      {showAddFood && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-md relative">
            <button
              className="absolute top-2 right-4 text-gray-600"
              onClick={() => setShowAddFood(false)}
            >
              ✕
            </button>
            <AddFood />
          </div>
        </div>
      )}

      {/* ✅ Popup for Writing Restaurant Review (not Menu Review!) */}
      {showReview && selectedRestaurantId && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-xl relative">
            <button
              className="absolute top-2 right-4 text-gray-600"
              onClick={() => setShowReview(false)}
            >
              ✕
            </button>
            <WriteRestReview rest_id={selectedRestaurantId} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
