import React from "react";
import { useNavigate } from "react-router-dom";

const RestaurantList = ({ 
  restaurants, 
  setShowReview, 
  setSelectedRestaurantId,  // ✅ Accept this prop from parent
  setShowOnlyRestaurants, 
  showOnlyRestaurants, 
  setShowOnlyFoods 
}) => {
  const navigate = useNavigate();

  const handleReadMore = (rest_id) => {
    if (!rest_id) return;
    navigate(`/restaurants/${rest_id}`); // ➡️ Restaurant Details
  };

  const handleWriteRestaurantReview = (rest_id) => {
    if (!rest_id) return;
    navigate(`/restaurants/${rest_id}/review`); // ➡️ Write Restaurant Review
  };

  return (
    <div className="w-full bg-white shadow-md rounded-md p-2 mt-2">
      <h3 className="text-lg font-semibold flex justify-between">
        Restaurants
        <span
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setShowOnlyRestaurants(true);
            setShowOnlyFoods(false);
          }}
        >
          {showOnlyRestaurants ? "Show All" : "More"}
        </span>
      </h3>

      {restaurants.length === 0 ? (
        <p className="text-gray-500">No restaurants found.</p>
      ) : (
        restaurants.map((restaurant) => (
          <div key={restaurant.rest_id} className="border rounded p-3 mt-2">
            <div className="flex items-start">
              <img
                src={restaurant.image || "default-image.png"}
                alt={restaurant.rest_name}
                className="w-20 h-20 object-cover rounded"
              />
              <div className="ml-3 flex flex-col">
                <h4 className="font-bold">{restaurant.rest_name}</h4>
                <p className="text-sm text-gray-600">{restaurant.address}</p>
                <p className="text-sm text-gray-600">{restaurant.rest_address2}</p>
                <p className="text-sm text-gray-600">{restaurant.rest_ph_number}</p>
                <div className="flex space-x-2 mt-2">
                  <button
                    className="bg-blue-500 text-white px-2 py-1 rounded text-sm"
                    onClick={() => handleReadMore(restaurant.rest_id)}
                  >
                    Read more
                  </button>
                  <button
                    className="bg-green-500 text-white px-2 py-1 rounded text-sm"
                    onClick={() => handleWriteRestaurantReview(restaurant.rest_id)}
                  >
                    Writing Review
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default RestaurantList;