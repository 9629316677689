import React from "react";
import { useNavigate } from "react-router-dom"; // ✅ Import useNavigate

const FoodList = ({ foods, showOnlyFoods, setShowOnlyFoods, setShowOnlyRestaurants }) => {
  const navigate = useNavigate(); // ✅ Initialize navigate

  return (
    <div className="w-full bg-white shadow-md rounded-md p-2 mt-2">
      <h3 className="text-lg font-semibold flex justify-between">
        Foods 
        <span 
          className="text-blue-500 cursor-pointer"
          onClick={() => {
            setShowOnlyFoods(true);
            setShowOnlyRestaurants(false);  // ✅ Hide Restaurants
          }}
        >
          More
        </span>
      </h3>

      {foods.length === 0 ? (
        <p className="text-gray-500">No food items found.</p>
      ) : (
        foods.map((food, index) => (
          <div key={index} className="border rounded p-3 flex items-center mt-2">
            <img
              src={food.image || "default-image.png"}
              alt={food.name}
              className="w-20 h-20 object-cover rounded"
            />
            <div className="ml-3">
              <h4 className="font-bold">{food.fd_name}</h4>
              <p className="text-sm">{food.englishName}</p>
              <p className="text-sm text-gray-600">
                {food.fd_avprice}원 / ${food.priceUSD}
              </p>
              <p className="text-sm text-blue-500">{food.reviews} Review / {food.rating}</p>
              <div className="flex space-x-2 mt-2">
                <button 
                  className="bg-blue-500 text-white px-2 py-1 rounded text-sm">
                  Read more
                </button>
                <button
                  className="bg-green-500 text-white px-2 py-1 rounded text-sm"
                  onClick={() => navigate(`/foods/${food.fd_id}/review`)} // ✅ navigate properly
                >
                Write Review
              </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default FoodList;
