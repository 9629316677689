import React, { useState, useEffect } from "react";
import axios from "axios";
import RestaurantList from "./RestaurantList";
import FoodList from "./FoodList";

const SearchResults = ({ 
  restaurants,
  foods,
  searchQuery, 
  setShowDetails, 
  showDetails, 
  setShowReview, 
  showOnlyRestaurants, 
  setShowOnlyRestaurants, 
  showOnlyFoods, 
  setShowOnlyFoods, 
  setSelectedRestaurantId // ✅ ADD THIS LINE
}) => {


  // // ✅ Fetch restaurants and foods on mount
  // useEffect(() => {
  //   const fetchRestaurants = async () => {
  //     try {
  //       const res = await axios.get("/api/display/restaurants");
  //       console.log("✅ Fetched Restaurants:", res.data);
  //       setRestaurants(res.data);
  //     } catch (error) {
  //       console.error("❌ Error fetching restaurants:", error);
  //     }
  //   };

  //   const fetchFoods = async () => {
  //     try {
  //       const res = await axios.get("/api/display/foods");
  //       console.log("✅ Fetched Foods:", res.data);
  //       setFoods(res.data);
  //     } catch (error) {
  //       console.error("❌ Error fetching foods:", error);
  //     }
  //   };

  //    fetchRestaurants();
  //   fetchFoods();
  // }, []);

  // ✅ Ensure `searchQuery` is always a string
  const query = typeof searchQuery === "string" ? searchQuery.toLowerCase() : "";

  // ✅ Filter Restaurants & Foods Based on Search Query
  const filteredRestaurants = restaurants.filter((r) =>
    r?.rest_name?.toLowerCase().includes(query) // Make sure you're using the correct property here
  );

  const filteredFoods = foods.filter((f) =>
    f?.fd_name?.toLowerCase().includes(query) // Correct property for food name
  );

  return (
    <div className="w-full bg-white p-1 rounded-lg shadow-md">
      {/* ✅ Show Restaurant List unless a restaurant is expanded */}
      {!showDetails && showOnlyRestaurants && (
        <div className="mt-4">
          {filteredRestaurants.length === 0 ? (
            <p className="text-gray-500">No restaurants found.</p>
          ) : (
            <RestaurantList 
              restaurants={filteredRestaurants} 
              setShowDetails={setShowDetails} // ✅ Pass function to update state
              setShowReview={setShowReview} // ✅ Pass setShowReview to RestaurantList
              setSelectedRestaurantId={setSelectedRestaurantId} // ✅ Required for RestaurantList
              setShowOnlyRestaurants={setShowOnlyRestaurants}
              setShowOnlyFoods={setShowOnlyFoods}
            />// ✅ Pass function to update state
          )}
        </div>
      )}

      {/* ✅ Hide foods if a restaurant is expanded */}
      {!showDetails && showOnlyFoods && (
        <div className="mt-4">
          {filteredFoods.length === 0 ? (
            <p className="text-gray-500">No food items found.</p>
          ) : (
            <FoodList
              foods={filteredFoods}
              setShowOnlyRestaurants={setShowOnlyRestaurants}
              setShowOnlyFoods={setShowOnlyFoods}
            />
          )}
        </div>
      )}

      {/* ✅ Show Restaurant Details when "Read More" is clicked */}
      {showDetails && (
        <div className="p-4 bg-gray-100 rounded-lg shadow-md">
          <h3 className="text-xl font-bold">{showDetails.rest_name}</h3> {/* Use rest_name for restaurant */}
          <p className="text-gray-600">{showDetails.rest_address1}</p> {/* Use correct address properties */}
          <p className="text-gray-600">{showDetails.rest_ph_number}</p> {/* Use correct phone property */}

          {/* ✅ Button to go back */}
          <button
            onClick={() => setShowDetails(null)} // ✅ Reset state
            className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
          >
            Back to List
            </button>
        </div>
      )}
    </div>
  );
};

export default SearchResults;