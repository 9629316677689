import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // ✅ Added for animation

const FloatingButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="fixed bottom-6 left-6">
      {/* "+" Floating Button */}
      <button
        className="w-14 h-14 bg-teal-500 text-white rounded-full shadow-lg flex items-center justify-center text-3xl"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        +
      </button>

      {/* Popup Menu */}
      <AnimatePresence>
        {menuOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.3 }}
            className="bg-white shadow-md rounded-lg p-2 absolute bottom-16 left-0 w-44"
          >
          <button  
            className="block w-full p-2 text-left hover:bg-gray-200"
            onClick={() => {
              setMenuOpen(false);
                navigate("/add-restaurant");
            }}
          >         
            ➕ Add Restaurant
          </button>
          <button 
            className="block w-full text-left px-4 py-2 hover:bg-gray-200"
            onClick={() => {
              setMenuOpen(false);
                navigate("/add-food");
              }}
          > 
            🍽️ Add Food 
          </button>
        </motion.div>
      )}
      </AnimatePresence>
    </div>
  );
};

export default FloatingButton;
