import axios from "axios";
import { uploadImages } from "./uploadImageUtil"; // your existing util

// ✅ 1. Restaurant Review Submission
export async function submitRestaurantReview({ rest_id, title, content, scores, tags, imageFiles }) {
  try {
    let uploadedPaths = [];
    if (imageFiles.length > 0) {
      uploadedPaths = await uploadImages(imageFiles, "restaurants_review", rest_id);
    }

    const reviewForm = new FormData();
    reviewForm.append("rest_id", rest_id);
    reviewForm.append("rv_rest_title", title);
    reviewForm.append("rv_rest_content", content);
    reviewForm.append("rv_rest_score_fq", scores.fq);
    reviewForm.append("rv_rest_score_sq", scores.sq);
    reviewForm.append("rv_rest_score_cq", scores.cq);
    reviewForm.append("rv_rest_score_iq", scores.iq);
    if (uploadedPaths.length > 0) {
      reviewForm.append("rv_rest_image", uploadedPaths[0]);
    }
    reviewForm.append("tags", JSON.stringify(tags));

    await axios.post("/api/reviews/add-restaurant", reviewForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    alert("✅ Restaurant review submitted successfully!");
  } catch (error) {
    console.error("❌ Error submitting restaurant review:", error);
    alert("❌ Failed to submit restaurant review.");
  }
}

// ✅ 2. Restaurant Menu Review Submission
export async function submitMenuReview({ rest_id, rest_menu_id, title, content, tscore, tags, imageFiles }) {
  try {
    let uploadedPaths = [];
    if (imageFiles.length > 0) {
      uploadedPaths = await uploadImages(imageFiles, "restaurants_menu_review", rest_id, rest_menu_id);
    }

    const reviewForm = new FormData();
    reviewForm.append("rest_id", rest_id);
    reviewForm.append("rest_menu_id", rest_menu_id);
    reviewForm.append("rv_menu_title", title);
    reviewForm.append("rv_menu_content", content);
    reviewForm.append("rv_menu_tscore", tscore);
    if (uploadedPaths.length > 0) {
      reviewForm.append("rv_menu_image", uploadedPaths[0]);
    }
    reviewForm.append("tags", JSON.stringify(tags));

    await axios.post("/api/reviews/add-menu", reviewForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    alert("✅ Menu review submitted successfully!");
  } catch (error) {
    console.error("❌ Error submitting menu review:", error);
    alert("❌ Failed to submit menu review.");
  }
}

// ✅ 3. Food Review Submission
export async function submitFoodReview({ fd_id, title, content, score, tags, imageFiles }) {
  try {
    let uploadedPaths = [];
    if (imageFiles.length > 0) {
      uploadedPaths = await uploadImages(imageFiles, "foods_review", fd_id);
    }

    const reviewForm = new FormData();
    reviewForm.append("fd_id", fd_id);
    reviewForm.append("rv_food_title", title);
    reviewForm.append("rv_food_content", content);
    reviewForm.append("rv_food_tscore", score);   // ✅ MATCH column name: rv_food_tscore_sq
    if (uploadedPaths.length > 0) {
      reviewForm.append("rv_food_image", uploadedPaths[0]);
    }
    reviewForm.append("tags", JSON.stringify(tags));

    await axios.post("/api/reviews/add-food", reviewForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    alert("✅ Food review submitted successfully!");
  } catch (error) {
    console.error("❌ Error submitting food review:", error);
    alert("❌ Failed to submit food review.");
  }
}
