import axios from "axios";

/**
 * Upload images using Upload API
 * 
 * @param {File[]} files - array of files (images)
 * @param {string} category - one of "foods", "foods_review", "restaurants", etc
 * @param {string} id - food ID, restaurant ID
 * @param {string} [subId] - optional (for restaurant foods)
 * @returns {Promise<string[]>} - returns uploaded image paths
 */
export const uploadImages = async (files, category, id, subId = null) => {
  if (!files || files.length === 0 || !id) {
    throw new Error("No files or missing id");
  }

  const formData = new FormData();
  formData.append("category", category);
  formData.append("id", id);
  if (subId) {
    formData.append("sub_id", subId);
  }

  files.forEach((file) => {
    formData.append("images", file);
  });

  const response = await axios.post("/api/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  if (!response.data.paths) {
    throw new Error("Upload failed");
  }

  return response.data.paths; // ✅ array of uploaded image paths
};
